.site-footer {
    padding: rem(12) rem(24);
    background-color: $dark-blue;
    color: rgba(#fff, 0.5);

    @media all and (min-width: em(768)) {
        display: flex;
    }

    &__copyright {
        margin-top: rem(12);
        text-align: center;

        @media all and (min-width: em(768)) {
            order: 1;
            flex: 1;
            text-align: left;
        }
    }

    &__social {
        display: flex;
        justify-content: center;

        @media all and (min-width: em(768)) {
            order: 2;
        }

        a {
            width: rem(40);
            height: rem(40);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: rem(6);
            margin-right: rem(6);
            border-radius: 50%;
            border: rem(2) solid #fff;
            opacity: 0.5;
            transition: opacity 0.25s;
            
            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__side {
        @media all and (min-width: em(768)) {
            order: 3;
            flex: 1;
        }
    }
}
