.register {
    &__container {
        max-width: rem(580);
        margin-left: auto;
        margin-right: auto;
    }

    &__form {
        .postcode-lookup__input {
            @media all and (min-width: em(520)) {
                width: rem(270);
                max-width: 100%;
            }
        }
    }
}
