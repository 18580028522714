.heading-1 {
    font-family: 'Merriweather', sans-serif;
    font-size: rem(24);

    @media all and (min-width: em(768)) {
        font-size: rem(32);
    }
}

.heading-2 {
    font-family: 'Merriweather', sans-serif;
    font-size: rem(16);

    @media all and (min-width: em(768)) {
        font-size: rem(18);
    }
}

.heading-3 {
    font-family: 'Merriweather', sans-serif;
    font-size: rem(14);

    @media all and (min-width: em(768)) {
        font-size: rem(16);
    }
}
