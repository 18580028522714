.postcode-lookup {
    margin-bottom: rem(16);

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__input-wrap {
        display: flex;
        align-items: flex-end;
        opacity: 1;
        transition: opacity 0.25s;

        &.loading {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &__search-btn {
        padding: rem(10);
        margin-left: rem(18);
        white-space: nowrap;
        font-size: rem(12);
    }

    &__address-select-wrap {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: height 0.5s, opacity 0.5s;

        &.active {
            height: auto;
            opacity: 1;
            transition: height 0.5s, opacity 0.5s 0.5s;
        }

        .input-group {
            margin-bottom: rem(16);
        }
    }

    &__address-inputs {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: height 0.5s, opacity 0.5s;

        &.active {
            height: auto;
            opacity: 1;
            transition: height 0.5s, opacity 0.5s 0.5s;
        }
    }

    &__manual-btn-wrap {
        height: auto;
        overflow: hidden;
        opacity: 1;
        transition: height 0.5s, opacity 0.5s 0.5s;

        &.hide {
            height: 0;
            opacity: 0;
            transition: height 0.5s, opacity 0.5s;
        }
    }

    &__manual-btn {
        text-decoration: underline;
        color: $gold;
    }
}
