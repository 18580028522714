.screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

.container {
    margin-left: auto;
    margin-right: auto;
}

.no-scroll {
    overflow: hidden;
}

.btn {
    display: inline-block;
    padding: rem(14) rem(28);
    border-radius: 2em;
    border: rem(1) solid;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0.1em;
    text-decoration: none;
    text-align: center;
    color: inherit;
    transition: background-color 0.25s, border-color 0.25s, color 0.25s;

    &.small {
        padding: rem(8) rem(16);
        font-size: rem(12);
    }

    &.wide {
        width: 100%;
        min-width: 10em;

        @media all and (min-width: em(520)) {
            width: auto;
        }
    }
}

.icon-btn {
    padding-top: rem(1);
    display: inline-flex;
    align-items: center;
    text-transform: uppercase;
    font-size: rem(12);
    line-height: 1;
    letter-spacing: 0.1em;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.25s, border-color 0.25s, color 0.25s;
    
    .icon {
        width: rem(12);
        margin-right: rem(8);
        position: relative;
        top: rem(-1);
    }
}

.background-dark-blue {
    background-color: $dark-blue;
}

.background-light-blue {
    background-color: $light-blue;
}

.background-gold {
    background-color: $gold;
}

.background-yellow {
    background-color: $yellow;
}

.background-table-blue {
    background-color: $table-blue;
}

.background-table-light-blue {
    background-color: $table-light-blue;
}

.background-table-dark-blue {
    background-color: $table-dark-blue;
}

.border-light-blue {
    border-color: $light-blue;
}

.border-gold {
    border-color: $gold;
}

.border-yellow {
    border-color: $yellow;
}

.border-white-trans {
    border-color: rgba(#fff, 0.3);
}

.border-bottom-white-5 {
    border-bottom: rem(5) solid #fff;
}

.hover-background-white {
    &:hover {
        background-color: #fff;
    }
}

.hover-background-light-blue {
    &:hover {
        background-color: $light-blue;
    }
}

.hover-background-yellow {
    &:hover {
        background-color: $yellow;
    }
}

.hover-border-light-blue {
    &:hover {
        border-color: $light-blue;
    }
}

.hover-border-yellow {
    &:hover {
        border-color: $yellow;
    }
}

.fill-white {
    fill: #fff;
}

.fill-gold {
    fill: $gold;
}

.fill-blue {
    fill: $blue;
}

.fill-dark-blue {
    fill: $dark-blue;
}

.fill-green {
    fill: $green;
}

.fill-red {
    fill: $red;
}

.fill-yellow {
    fill: $yellow;
}

.fill-alert-red {
    fill: $alert-red;
}

.fill-alert-blue {
    fill: $alert-blue;
}

.fill-alert-yellow {
    fill: $alert-yellow;
}

.fill-table-dark-blue {
    fill: $table-dark-blue;
}

.hover-fill-blue {
    &:hover {
        fill: $blue;
    }
}

.hover-fill-gold {
    &:hover {
        fill: $gold;
    }
}

.site-main {
    .fill-container {
        margin-left: rem(-14);
        margin-right: rem(-14);

        @media all and (min-width: em(1024)) {
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.hide-0-580 {
    display: none;

    @media all and (min-width: em(580)) {
        display: block;
    }
}

.hide-580 {
    @media all and (min-width: em(580)) {
        display: none;
    }
}

.hide-0-768 {
    display: none;

    @media all and (min-width: em(768)) {
        display: block;
    }
}

.hide-768 {
    @media all and (min-width: em(768)) {
        display: none;
    }
}

.hide-0-1024 {
    display: none;

    @media all and (min-width: em(1024)) {
        display: block;
    }
}

.hide-1024 {
    @media all and (min-width: em(1024)) {
        display: none;
    }
}

.width-auto- {
    width: auto;
}

.max-width-280 {
    max-width: rem(280);

    &__500 {
        @media all and (min-width: em(500)) {
            max-width: rem(280);
        }
    }
}

.max-width-390 {
    max-width: rem(390);
}

.max-width-500 {
    max-width: rem(500);
}

.max-width-580 {
    max-width: rem(580);
}

.max-width-1200 {
    max-width: rem(1200);
}

.no-wrap {
    white-space: nowrap;
}

.display-inline-block {
    display: inline-block;
}
