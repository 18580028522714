.text-white {
    color: #fff;
}

.text-dark-blue {
    color: $dark-blue;
}

.text-gold {
    color: $gold;
}

.text-blue-trans {
    color: rgba($blue, 0.5);
}

.hover-text-blue {
    &:hover {
        color: $blue;
    }
}

.hover-text-dark-blue {
    &:hover {
        color: $dark-blue;
    }
}

.hover-text-gold {
    &:hover {
        color: $gold;
    }
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.font-weight-bold {
    font-weight: 700;
}
