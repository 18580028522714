*, *:before, *:after {
    box-sizing: inherit;
}

body {
    background-color: $grey;
    box-sizing: border-box;
    font-size: 87.5%;
    line-height: 1.5;
    font-family: 'Lato', sans-serif;
    color: $dark-blue;
    letter-spacing: 0.02em;
}

body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
}

a {
    color: $gold;
}

button {
    padding: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    font-family: inherit;
    line-height: inherit;
    color: inherit;
    font-weight: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    cursor: pointer;
}

ul {
    list-style-type: none;

    li {
        position: relative;
        padding-left: 0.5em;

        &:before {
            content: '·';
            position: absolute;
            left: 0;
        }
    }
}

img {
    width: auto;
    height: auto;
    max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

p {
    margin-top: 1em;
    margin-bottom: 1em;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

td, th {
    background-color: $table-light-blue;
    border: rem(1) solid #fff;
}

th {
    font-weight: normal;
}
