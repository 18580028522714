.ui-kit {
    &__column-wrap {
        @media all and (min-width: em(768)) {
            display: flex;
            flex-wrap: wrap;
            margin-left: rem(-16);
            margin-right: rem(-16);
        }
    }

    &__column {
        @media all and (min-width: em(768)) {
            flex: 1;
            padding-left: rem(16);
            padding-right: rem(16);
        }
    }

    hr {
        width: 100%;
        height: rem(2);
        background-color: $grey;
        border: none;
        margin-top: rem(30);
        margin-bottom: rem(30);
    }
}
