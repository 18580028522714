.nominations-table {
    display: block;

    @media all and (min-width: em(700)) {
        display: table;
        table-layout: fixed;
    }
    
    tbody, tfoot, tr, td, th {
        display: block;
    }

    thead {
        display: none;

        @media all and (min-width: em(700)) {
            display: table-header-group;
        }
    }

    tbody {
        @media all and (min-width: em(700)) {
            display: table-row-group
        }
    }

    tfoot {
        @media all and (min-width: em(700)) {
            display: table-footer-group;
        }

        .icon-btn {
            display: flex;
        }
    }

    tr {
        border-bottom: rem(2) solid #fff;

        @media all and (min-width: em(700)) {
            display: table-row;
            border-bottom: none;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    td, th {
        @media all and (min-width: em(700)) {
            display: table-cell;
        }
    }

    .label-text {
        @media all and (min-width: em(700)) {
            display: none;
        }
    }
}

.input-wrap {
    .address-textarea {
        min-height: rem(100);

        @media all and (min-width: em(520)) {
            max-width: rem(370);
        }
    }
}
