.modal-pop-up {
    width: 100%;
    height: 100%;
    position: fixed;
    top: -100%;
    left: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    opacity: 0;
    background-color: rgba(0,0,0,0.5);
    overflow: auto;
    pointer-events: none;
    transition: top 0s 0.25s, opacity 0.25s;

    &.active {
        top: 0;
        opacity: 1;
        pointer-events: auto;
        transition: opacity 0.25s;

        .modal__inner {
            transform: translateY(0);
            transition: transform 0.25s;
        }
    }
    
    &__inner {
        width: 100%;
        max-height: 100%;
        overflow: auto;
        padding: rem(16);
        transform: translateY(1rem);
        transition: transform 0s 0.25s;
    }

    &__content {
        width: 100%;
        max-width: rem(320);
        margin-left: auto;
        margin-right: auto;
        background-color: #000;
        position: relative;
        z-index: 1;
        padding: rem(48) rem(36);
        border-radius: rem(4);

        @media all and (min-width: em(768)) {
            max-width: rem(580);
            padding: rem(60) rem(100);
        }
    }

    &__close-bg-btn {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 0;
    }

    &__close-btn {
        width: rem(26);
        height: rem(26);
        display: block;
        position: absolute;
        top: rem(16);
        right: rem(16);
        font-size: 0;

        @media all and (min-width: em(768)) {
            top: rem(32);
            right: rem(32);
        }

        &:before, &:after {
            content: '';
            width: 100%;
            height: 2px;
            position: absolute;
            top: 50%;
            left: 0;
            background-color: #fff;
        }

        &:before {
            transform: translateY(-50%) rotate(45deg);
        }

        &:after {
            transform: translateY(-50%) rotate(-45deg);
        }
    }
}

.admin-bar {
	.modal-pop-up {
        padding-top: rem(46);

        @media all and (min-width: em(783)) {
            padding-top: rem(32);
        }
    }
}
