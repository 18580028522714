.context-menu {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    
    &__menu {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2;
        opacity: 0;
        pointer-events: none;
        background-color: #fff;
        border: rem(1) solid $border;
        margin: 0;
        padding: rem(9) 0;
        color: $dark-blue;
        font-size: rem(12);
        list-style: none;
        transition: opacity 0.25s;

        @media all and (min-width: em(768)) {
            width: max-content;
            position: absolute;
            top: 100%;
            bottom: auto;
            left: auto;
            right: auto;
            padding-top: 0;
            padding-bottom: 0;

            &.left {
                left: 50%;
                transform: translateX(#{rem(-18)});
            }
    
            &.right {
                right: 50%;
                transform: translateX(#{rem(18)});
            }
        }

        &:hover, &.active {
            opacity: 1;
            pointer-events: auto;
        }

        li {
            padding: 0;
            position: relative;

            &:last-child {
                &:after {
                    content: none;
                }
            }

            &:before {
                content: none;
            }

            &:after {
                content: '';
                height: rem(1);
                position: absolute;
                bottom: 0;
                left: rem(26);
                right: rem(26);
                background-color: $grey;

                @media all and (min-width: em(768)) {
                    left: rem(8);
                    right: rem(8);
                }
            }

            > a, > button {
                padding: rem(9) rem(26);
                text-decoration: none;
                color: $blue;

                @media all and (min-width: em(768)) {
                    padding: rem(8);
                }
            }
        }
    }

    &__close-bg {
        width: 100%;
        height: 100%;
        position: fixed;
        top: -100%;
        left: 0;
        z-index: 1;
        opacity: 0;
        pointer-events: none;
        background-color: rgba(0,0,0,0.3);
        transition: opacity 0.25s;

        @media all and (min-width: em(768)) {
            display: none;
        }

        &.active {
            top: 0;
            opacity: 1;
            pointer-events: auto;
        }
    }
}
