.table {
    width: 100%;
    border-collapse: collapse;
    font-size: rem(12);

    @media all and (min-width: em(1024)) {
        font-size: rem(14);
    }

    th, td {
        vertical-align: top;
        padding: rem(6) rem(9);

        @media all and (min-width: em(1024)) {
            padding: rem(9) rem(14);
        }

        &:first-child {
            border-left: none;
        }

        &:last-child {
            border-right: none;
        }
    }

    thead, tbody {
        &:first-child {
            tr {
                &:first-child {
                    td, th {
                        border-top: none;
                    }
                }
            }
        }

        &:last-child {
            tr {
                &:last-child {
                    td, th {
                        border-bottom: none;
                    }
                }
            }
        }
    }

    thead {
        th {
            text-align: left;
        }
    }
    
    tbody {
        th {
            text-align: left;
        }
    }

    .table-no-top-bottom-border-cell {
        border-top: none;
        border-bottom: none;
    }
}

.table-shrink-cell {
    width: 0;
    min-width: rem(80);
    white-space: nowrap;

    @media all and (min-width: em(580)) {
        min-width: rem(100);
    }
}

.table-cell-fill-space {
    width: 100%;
}
