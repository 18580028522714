@import '~normalize.css';

// Essentials
@import 'tools/tools';
@import 'settings/settings';

// Generic
@import 'generic/generic';

// Layouts
@import 'components/components';

// Pages
@import 'pages/pages';
