.input-group {
    margin-top: rem(16);
    margin-bottom: rem(16);
    margin-left: rem(-8);
    margin-right: rem(-8);
    display: flex;
    flex-wrap: wrap;
    text-align: initial;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.auto-width {
        .input-wrap {
            width: auto;
            flex: unset;
        }
    }
}

.input-wrap {
    width: 100%;
    margin-bottom: rem(16);

    @media all and (min-width: em(580)) {
        flex: 1;
        margin-bottom: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &.half-width-sm {
        @media all and (min-width: em(768)) {
            width: 50%;
            flex: unset;
        }
    }

    > label {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        > * {
            padding-left: rem(8);
            padding-right: rem(8);
        }
    }

    .date-selects, .select, .input, input, select, textarea {
        width: 100%;
    }
}

input, select, textarea {
    width: 100%;
    display: block;
    margin-top: rem(8);
    padding: rem(8);
    background-color: #fff;
    border: rem(1) solid $border;
    border-radius: rem(4);
    color: $blue;

    &::placeholder {
        color: rgba($blue, 0.5)
    }

    &:first-child {
        margin-top: 0;
    }
}

select {
    appearance: none;
}

textarea {
    min-height: rem(160);
    resize: vertical;

    &.input-mini {
        min-height: rem(60);
    }
}

.select {
    position: relative;

    &__icon {
        width: rem(14);
        position: absolute;
        top: 50%;
        right: rem(20);
        transform: translateY(-50%);
        pointer-events: none;
    }

    select {
        padding-right: rem(38);
    }
}

.input-mini {
    padding: rem(4);
}

.checkbox-group {
    margin-top: rem(64);
    margin-bottom: rem(64);
}

.checkbox-wrap {
    display: inline-flex;
    align-items: center;
    font-weight: normal;

    &:hover {
        .checkbox {
            border-color: $blue;
        }
    }

    i {
        color: rgba($blue, 0.6);
    }

    input {
        &[type="checkbox"], &[type="radio"] {
            border: 0;
            clip: rect(1px, 1px, 1px, 1px);
            clip-path: inset(50%);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute !important;
            width: 1px;
            word-wrap: normal !important;

            &:checked {
                + .checkbox {
                    background-color: $blue;
                    border-color: $blue;

                    svg {
                        stroke-dashoffset: 0;
                    }
                }
            }
        }
    }

    .checkbox {
        width: rem(20);
        height: rem(20);
        min-width: rem(20);
        align-self: flex-start;
        margin-right: rem(10);
        border-radius: rem(2);
        border: rem(1) solid $border;
        background-color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.25s, border-color 0.25s;
        
        svg {
            width: rem(12);
            fill: none;
            stroke: #fff;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-dasharray: 17;
            stroke-dashoffset: 17;
            transition: stroke-dashoffset 0.25s;        
        }
    }
}

.pristine-error {
    margin-top: rem(8);
    font-weight: 400;
    color: $red;
}

.submit-btn {
    margin-top: rem(40);
}

.date-selects {
    display: flex;
    align-items: center;
    margin-left: rem(-8);
    margin-left: rem(-8);

    .select {
        min-width: rem(80);
        margin-top: 0;
    }

    > div {
        padding-left: rem(8);
        padding-left: rem(8);
    }
}

.label-text {
    width: 100%;
    margin-bottom: rem(8);
    font-weight: 700;
}

.input-append {
    min-height: rem(34);
    display: flex;
    align-items: center;
    color: $table-dark-blue;
}