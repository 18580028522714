.site-sidebar {
    display: none;

    @media all and (min-width: em(580)) {
        display: block;
        width: 100%;
        margin-bottom: rem(40);
    }

    @media all and (min-width: em(620)) {
        width: rem(120);
        margin-right: rem(60);
    }

    @media all and (min-width: em(1024)) {
        width: rem(220);
        margin-right: rem(120);
    }

    &__nav-link {
        &.active {
            > a {
                color: $gold;

                &:after {
                    opacity: 1;
                }
            }
        }

        a {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: rem(8);
            padding-bottom: rem(8);
            position: relative;
            border-bottom: rem(1) solid $grey;
            text-decoration: none;
            color: $blue;
            transition: color 0.25s;

            @media all and (min-width: em(1024)) {
                padding-top: rem(12);
                padding-bottom: rem(12);
            }

            &:after {
                content: '';
                width: 100%;
                height: rem(2);
                position: absolute;
                bottom: rem(-1);
                left: 0;
                background-color: $gold;
                opacity: 0;
                transition: opacity 0.25s;
            }

            &:hover {
                color: $gold;

                &:after {
                    opacity: 1;
                }

                .icon {
                    fill: $gold;
                }
            }

            .icon {
                height: rem(9);
                transform: rotate(0deg);
                transition: transform 0.25s, fill 0.25s;
                margin-right: rem(1);
                fill: $blue;
            }
        }
    }

    .has-sub-menu {
        &.active {
            > a {
                color: $gold;

                &:after {
                    opacity: 1;
                }

                .icon {
                    transform: rotate(90deg);
                    fill: $gold;
                }
            }

            > .sub-menu {
                height: auto;
                opacity: 1;
                transition: height 0.25s, opacity 0.25s 0.25s;
            }
        }
    }

    .sub-menu {
        height: 0;
        margin-top: 0;
        overflow: hidden;
        position: relative;
        padding-top: 0;
        padding-bottom: 0;
        opacity: 0;
        transition: height 0.25s, opacity 0.25s;

        &:after {
            content: '';
            width: 100%;
            height: rem(1);
            display: block;
            background-color: $grey;
        }

        .site-sidebar__nav-link {
            margin-top: rem(12);
            margin-bottom: rem(12);

            @media all and (min-width: em(1024)) {
                margin-top: rem(20);
                margin-bottom: rem(20);
            }

            &:first-child {
                margin-top: rem(16);

                @media all and (min-width: em(1024)) {
                    margin-top: rem(24);
                }
            }

            &:last-child {
                margin-bottom: rem(16);

                @media all and (min-width: em(1024)) {
                    margin-bottom: rem(24);
                }
            }

            a {
                border-bottom: none;
                padding-top: 0;
                padding-bottom: 0;
                margin-left: rem(20);
        
                @media all and (min-width: em(580)) {
                    margin-left: rem(12);
                }
        
                @media all and (min-width: em(1024)) {
                    margin-left: rem(20);
                }

                &:after {
                    content: none;
                }
            }
        }
    }
}
