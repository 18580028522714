.text-block {
    margin-top: rem(40);
    margin-bottom: rem(40);
    
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
