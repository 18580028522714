.tab-nav {
    margin-top: rem(20);
    margin-bottom: rem(20);
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 700;
    
    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__label {
        margin-top: rem(4);
        margin-bottom: rem(4);
        margin-right: rem(12);
        
        @media all and (min-width: em(580)) {
            font-size: rem(18);
        }
    }

    &__links {
        display: flex;
        flex-wrap: wrap;
        font-size: rem(12);
        
        @media all and (min-width: em(580)) {
            font-size: rem(14);
        }

        a {
            padding: rem(7) rem(9);
            margin-right: rem(2);
            background-color: $table-light-blue;
            text-decoration: none;
            letter-spacing: 0.1em;
            transition: background-color 0.25s;

            &:hover, &.active {
                background-color: $blue;
                color: #fff;
            }
        }
    }
}
