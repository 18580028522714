.site-main {
    background-color: #fff;
    padding: rem(40) rem(16);

    @media all and (min-width: em(620)) {
        display: flex;
    }

    @media all and (min-width: em(768)) {
        padding: rem(60) rem(30);
    }

    @media all and (min-width: em(1200)) {
        padding: rem(90) rem(60);
    }
}

.site-content {
    @media all and (min-width: em(620)) {
        flex: 1;
    }
}
