$blue: #163659;
$dark-blue: #112E4D;
$light-blue: #D9E9F5;

$gold: #AE966C;

$yellow: #FBB415;
$light-yellow: #FFF7AE;

$green: #36D06A;

$grey: #EEEBE8;

$red: #CD2C4E;

$table-blue: #D9E9F5;
$table-light-blue: #F0F9FF;
$table-dark-blue: #8FA7C1;

$border: #CCC9C7;

$alert-red: #CD2C4E;
$alert-blue: #50B4FF;
$alert-yellow: #FBB415;
