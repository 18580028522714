.key {
    box-shadow: 0 0 0 rem(1) $grey;
    background-color: rgba($light-yellow, 0.2);
    overflow: hidden;
    transition: height 0.5s;

    &.active {
        summary {
            position: relative;
            padding: rem(18);
            cursor: pointer;
            font-weight: 700;
    
            &::marker {
                content: none;
            }
    
            &:before {
                transform: translateY(calc(-50% - 1em));
                opacity: 0;
            }
    
            &:after {
                transform: translateY(-50%);
                opacity: 1;
            }
        }

        .key__content {
            opacity: 1;
            transition: opacity 0.5s 0.5s;
        }
    }

    summary {
        position: relative;
        padding: rem(18);
        cursor: pointer;
        font-weight: 700;

        &::marker {
            content: none;
        }

        &:before {
            content: 'Show';
            transform: translateY(-50%);
            opacity: 1;
        }

        &:after {
            content: 'Hide';
            transform: translateY(calc(-50% + 1em));
            opacity: 0;
        }

        &:before, &:after {
            position: absolute;
            top: 50%;
            right: rem(18);
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: $gold;
            font-size: rem(12);
            pointer-events: none;
            transition: transform 0.25s, opacity 0.25s;
        }
    }

    &__content {
        padding: rem(1) rem(18) rem(18);
        opacity: 0;
        transition: opacity 0.5s;

        > * {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
