.login {
    &__forgot-password {
        margin-top: rem(8);
        color: $gold;
    }

    &__submit {
        margin-top: rem(32);
    }

    &__register {
        margin-top: rem(52);
        display: flex;
        align-items: baseline;
        flex-wrap: wrap;

        h2 {
            margin-right: rem(12);
        }

        a {
            color: $gold;
        }
    }
}
