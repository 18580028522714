.column-wrap {
    display: flex;
    justify-content: space-between;
    margin-left: rem(-8);
    margin-right: rem(-8);
}

.column {
    padding-left: rem(8);
    padding-right: rem(8);

    .icon__image {
        width: auto;
    }
}
