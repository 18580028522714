.tooltip {
    position: relative;
    display: block;

    &:hover, &:focus {
        .tooltip {
            &__text {
                opacity: 1;
            }
        }
    }

    &__text {
        width: max-content;
        position: absolute;
        bottom: calc(100% + #{rem(6)});
        opacity: 0;
        pointer-events: none;
        background-color: $dark-blue;
        padding: rem(12);
        color: #fff;
        font-size: rem(12);
        transition: opacity 0.25s;

        &.left {
            left: 50%;
            transform: translateX(#{rem(-18)});

            &:after {
                left: rem(12);
            }
        }

        &.right {
            right: 50%;
            transform: translateX(#{rem(18)});

            &:after {
                right: rem(12);
            }
        }

        &:after {
            content: '';
            width: 0; 
            height: 0; 
            border-left: rem(6) solid transparent;
            border-right: rem(6) solid transparent;
            border-top: rem(6) solid $dark-blue;
            position: absolute;
            top: 100%;
        }
    }
}
