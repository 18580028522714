.lot-documentation {
    &__table {
        table-layout: fixed;

        &.table {
            tbody {
                .lot-documentation__cell {
                    vertical-align: middle;
                    padding: 0;
                }
            }
    
            thead {
                th {
                    padding: rem(4) rem(12);
                    font-size: rem(9);

                    @media all and (min-width: em(768)) {
                        font-size: rem(10);
                    }

                    &.text-center {
                        padding-left: 0;
                        padding-right: 0;
                        text-align: center;
                    }
                }
            }
        }

        tbody {
            margin-bottom: rem(10);

            &:last-child {
                margin-bottom: 0;
            }
        }

        .icon {
            margin-left: auto;
            margin-right: auto;
            justify-content: center;

            &__image {
                width: rem(14);

                @media all and (min-width: em(768)) {
                    width: rem(12);
                    height: rem(12);
                }
            }
        }

        .context-menu {
            .icon {
                justify-content: unset;
            }
        }
    }
    
    &__cell {
        width: rem(35);
        text-align: center;
    }

    &__label {
        padding: rem(2) rem(4);
        background-color: $table-blue;
        font-weight: 700;
    }

    &__icon {
        display: flex;
        justify-content: center;
        padding: rem(6) rem(4);
        font-size: 0;

        @media all and (min-width: em(768)) {
            padding-top: rem(9);
            padding-bottom: rem(9);
        }
    }
}
