.card-group {
    margin-top: rem(40);
    margin-bottom: rem(40);
}

.card, .small-card {
    background-color: $table-light-blue;
    margin-top: rem(20);
    margin-bottom: rem(20);

    &__header {
        padding: rem(10) rem(20);
        background-color: $table-blue;
        font-weight: 700;

        @media all and (min-width: em(1024)) {
            padding: rem(14) rem(40);
        }
    }

    a {
        color: $gold;
    }

    .checkbox-group {
        margin-top: rem(16);
        margin-bottom: rem(16);

        &:first-child {
            margin-top: 0;
        }
    
        &:last-child {
            margin-bottom: 0;
        }

        .input-group {
            margin-top: rem(8);
            margin-bottom: rem(8);

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .input-wrap {
            margin-bottom: rem(8);

            @media all and (min-width: em(580)) {
                margin-bottom: 0;
            }
        
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .table {
        background-color: #fff;

        th, td {
            padding: rem(8) rem(10);
            border-color: $table-light-blue;
        }
    
        thead {
            tr {
                &:first-child {
                    th, td {
                        border-top: none;
                    }
                }

                &:last-child {
                    th, td {
                        border-bottom: none;
                    }
                }
            }

            th {
                background-color: $table-blue;
            }
        }

        tbody {
            tr {
                &:first-child {
                    th, td {
                        border-top: none;
                    }
                }

                &:last-child {
                    th, td {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

.card {
    &__content {
        padding: rem(20);

        @media all and (min-width: em(1024)) {
            padding: rem(40);
        }
    }
}

.small-card {
    &__content {
        padding: rem(20);
    }
}
