.site-top-nav {
    padding: rem(12) rem(10);
    background-color: $dark-blue;
    overflow: hidden;
    color: $gold;
    font-size: rem(9);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;

    @media all and (min-width: em(420)) {
        font-size: rem(10);
    }

    @media all and (min-width: em(1024)) {
        padding: rem(12) rem(36);
        font-size: rem(13);
    }

    &__inner {
        margin-left: rem(-3);
        margin-right: rem(-3);

        @media all and (min-width: em(580)) {
            margin-left: rem(-6);
            margin-right: rem(-6);
            display: flex;
            justify-content: space-between;
        }

        @media all and (min-width: em(768)) {
            margin-left: rem(-12);
            margin-right: rem(-12);
        }

        @media all and (min-width: em(1024)) {
            margin-left: rem(-16);
            margin-right: rem(-16);
        }
    }

    &__locations {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__actions {
        display: none;

        @media all and (min-width: em(580)) {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
    }

    a {
        margin-left: rem(3);
        margin-right: rem(3);
        text-decoration: none;
        transition: color 0.25s;

        @media all and (min-width: em(580)) {
            margin-left: rem(6);
            margin-right: rem(6);
        }

        @media all and (min-width: em(768)) {
            margin-left: rem(12);
            margin-right: rem(12);
        }

        @media all and (min-width: em(1024)) {
            margin-left: rem(16);
            margin-right: rem(16);
        }

        &:hover, &.active {
            color: #fff;
        }
    }
}

.site-header {
    background-color: $blue;
    padding: rem(28) rem(16);

    @media all and (min-width: em(580)) {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(40) rem(24);
    }

    @media all and (min-width: em(1024)) {
        padding-left: rem(64);
        padding-right: rem(64);
    }

    &__space {
        display: none;

        @media all and (min-width: em(820)) {
            flex: 1;
            display: block;
        }
    }

    &__logo {
        display: flex;
        align-items: baseline;
        justify-content: center;
        text-align: center;
        color: #fff;
        font-size: rem(14);
        line-height: 1;
        font-weight: 700;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        white-space: nowrap;

        @media all and (min-width: em(820)) {
            display: block;
            margin-left: rem(24);
            margin-right: rem(24);
            font-size: rem(16);
        }
 
        img {
            height: rem(16);
            display: block;
            margin-right: rem(12);

            @media all and (min-width: em(820)) {
                height: rem(22);
                margin-bottom: rem(18);
                margin-right: 0;
            }

            @media all and (min-width: em(1200)) {
                height: rem(30);
            }
        }
    }

    &__links {
        margin-top: rem(28);
        display: flex;
        align-items: center;

        @media all and (min-width: em(580)) {
            flex: 1;
            margin-top: 0;
        }

        &__buttons {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            margin-left: auto;
            box-shadow: 0 0 rem(1) rem(1) inset $light-blue;
            border-radius: 2em;

            a {
                display: inline-block;
                padding: rem(12);
                border-radius: 2em;
                font-weight: 700;
                text-transform: uppercase;
                line-height: 1;
                letter-spacing: 0.1em;
                text-decoration: none;
                text-align: center;
                color: #fff;
                font-size: rem(12);
                transition: background-color 0.25s;

                @media all and (min-width: em(1024)) {
                    padding: rem(12) rem(20);
                    font-size: rem(14);
                }

                &.active {
                    background-color: $light-blue;
                    color: $blue;
                }
            }
        }
    }
}

.site-header-nav-open-button {
    width: rem(32);
    height: rem(32);
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @media all and (min-width: em(580)) {
        display: none;
    }

    > div {
        width: rem(14);
        height: rem(2);
        background-color: $blue;
        margin-bottom: rem(3);

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}

.site-bottom-nav {
    display: none;

    @media all and (min-width: em(580)) {
        display: flex;
        justify-content: center;
        position: relative;
        background-color: #fff;

        &:after {
            content: '';
            width: 100%;
            height: rem(2);
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: $grey;
        }
    }

    &__link {
        @media all and (min-width: em(580)) {
            padding: rem(12) rem(18);
            border-bottom: rem(2) solid transparent;
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            font-size: rem(12);
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: 0.1em;
            text-align: center;
            color: $blue;
            transition: border-bottom-color 0.25s;

            &.active, &:hover {
                border-bottom-color: $blue;
            }
        }

        @media all and (min-width: em(768)) {
            padding: rem(12) rem(24);
            border-bottom: rem(2) solid transparent;
            position: relative;
            z-index: 1;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            letter-spacing: 0.1em;
            transition: border-bottom-color 0.25s;

            &.active, &:hover {
                border-bottom-color: $blue;
            }
        }

        @media all and (min-width: em(1024)) {
            padding: rem(18) rem(40);
        }
    }
}

.site-mobile-menu {
    width: 100%;
    height: 100%;
    position: fixed;
    top: -100%;
    left: 0;
    z-index: 1;
    transform: translateX(2rem);
    opacity: 0;
    background-color: $blue;
    display: flex;
    flex-direction: column;
    overflow: auto;
    color: #fff;
    transition: top 0s 0.5s, transform 0.5s, opacity 0.5s;

    @media all and (min-width: em(580)) {
        display: none;
    }

    &.active {
        top: 0;
        transform: translateX(0);
        opacity: 1;
        transition: transform 0.5s, opacity 0.5s;
    }

    &__top {
        padding: rem(28);
        position: relative;
        text-align: center;
        color: #fff;
    }

    &__header {
        margin-bottom: rem(8);
        padding-left: 0.1em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
    }

    &__welcome {
        color: $gold;
    }

    &__nav {
        border-top: rem(1) solid #426178;
        flex: 1;

        &__link {
            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: rem(16) rem(28);
                border-bottom: rem(1) solid #426178;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 0.1em;
                color: #fff;

                &.logout {
                    background-color: $gold;
                    border-bottom: none;
                }
            }
        }
    }

    &__bottom-nav {
        padding: rem(16) rem(28);

        &__link {
            display: block;
            margin-bottom: rem(12);
            text-decoration: none;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            color: #fff;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .has-sub-menu {
        > a .icon {
            transform: rotate(0deg);
            transition: transform 0.25s;
        }

        &.active {
            > a .icon {
                transform: rotate(90deg);
            }

            > .sub-menu {
                height: auto;
                opacity: 1;
                transition: height 0.25s, opacity 0.25s 0.25s;
            }
        }
    }

    .sub-menu {
        height: 0;
        margin-top: 0;
        overflow: hidden;
        padding: 0;
        opacity: 0;
        background-color: $light-blue;
        transition: height 0.25s, opacity 0.25s;

        .site-mobile-menu__nav__link {
            &:last-child {
                a {
                    border-bottom: none;
                }
            }

            a {
                border-bottom-color: #fff;
                color: $blue;
                text-transform: none;
                letter-spacing: 0.02em;
            }
        }

        .sub-menu {
            padding-left: rem(28);
            padding-right: rem(28);
            background-color: #fff;

            .site-mobile-menu__nav__link a {
                border-bottom-color: $light-blue;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}

.site-header-nav-close-button {
    width: rem(32);
    height: rem(32);
    position: absolute;
    top: rem(22);
    right: rem(12);

    > div {
        width: rem(14);
        height: rem(2);
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: #fff;
        border-radius: 0.5em;

        &:first-of-type {
            transform: translate(-50%,-50%) rotate(45deg);
        }

        &:last-of-type {
            transform: translate(-50%,-50%) rotate(-45deg);
        }
    }
}
