.icon {
    margin-top: rem(16);
    margin-bottom: rem(16);
    display: flex;
    align-items: center;
    transition: fill 0.25s;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &__image {
        width: rem(30);
        height: rem(22);
        margin-right: rem(12);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        &:last-child {
            margin-right: 0;
        }

        svg {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.ref-icon {
    color: $gold;
    text-decoration: underline;
    font-size: rem(12);
}